.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    background-color: #333;
    color: white;
  }
  
  .navbar-logo a {
    color: white;
    text-decoration: none;
    font-size: 24px;
  }
  
  .navbar-links {
    list-style: none;
    display: flex;
  }
  
  .navbar-links li {
    margin: 0 15px;
  }
  
  .navbar-links a {
    color: white;
    text-decoration: none;
    position: relative;
    padding-bottom: 5px;
    transition: color 0.3s ease;
  }
  
  .navbar-links a::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ff5733;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .navbar-links a:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  
  .navbar-links a:hover {
    color: #ff5733;
  }
  