/* Home.css */
.home {
    text-align: center;
    margin-top: 0;
  }
  
  .home-image {
    width: 100%;
    height: 60vh; /* Adjusted height for the image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    animation: slideInFromTop 1s ease-out;
  }
  
  .home-content {
    margin-top: 20px;
    padding: 20px;
  }
  
  .animated-welcome {
    font-size: 2.5em;
    font-weight: bold;
    color: black; /* Set text color to black */
    margin-bottom: 10px;
  }
  
  .animated-subtext {
    font-size: 1.2em;
    color: black; /* Set text color to black */
  }
  
  .animated-letter {
    display: inline-block;
    opacity: 0;
    animation: letterDrop 0.5s forwards ease-out;
  }
  
  .space {
    display: inline-block;
    width: 0.3em; /* Adjust width for space between words */
  }
  
  @keyframes letterDrop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  