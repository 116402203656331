* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

body {
  background-color: #f0f0f0;
}

h2 {
  margin-bottom: 20px;
  font-size: 32px;
  color: #333;
}
