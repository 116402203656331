/* Updated Services.css */

.services {
    text-align: center;
    padding: 40px 20px;
    position: relative;
  }
  
  .services-image {
    width: 100%;
    height: 50vh; /* Adjust the height as needed */
    background-size: cover;
    background-position: center;
    background-attachment: fixed; /* Optional: for a parallax effect */
    margin-bottom: 30px;
  }
  
  
  h2 {
    font-size: 2.5em;
    color: black;
    margin-bottom: 10px;
  }
  
  .services-intro {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 30px;
    padding: 0 10%;
  }
  
  .services-columns {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    position: relative;
  }
  
  .service-box {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    opacity: 0; /* Initially hidden for animation */
    transition: transform 0.6s ease, opacity 0.6s ease;
    position: relative;
  }
  
  /* Animation classes */
  .animate-in {
    opacity: 1; /* Make visible when animation starts */
  }
  
  /* Different directions for animations */
  .from-left {
    animation: slideInFromLeft 0.6s ease forwards;
  }
  
  .from-right {
    animation: slideInFromRight 0.6s ease forwards;
  }
  
  .from-bottom {
    animation: slideInFromBottom 0.6s ease forwards;
  }
  
  .from-top {
    animation: slideInFromTop 0.6s ease forwards;
  }
  
  /* Keyframes for animations */
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @media (max-width: 768px) {
    .services-columns {
      grid-template-columns: 1fr;
    }
  }
  