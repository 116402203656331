.contact {
    text-align: center;
    padding: 60px 20px;
    position: relative;
    min-height: 70vh;
}

.contact-image {
    width: 100%;
    height: 50vh;
    background-size: cover;
    background-position: center;
    animation: slideInFromTop 1s ease-out;
}

h2 {
    margin-top: 20px;
    font-size: 2.5em;
    color: #333;
    margin-bottom: 0;
}

.contact-details-box {
    display: inline-block;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    max-width: 400px;
    width: 80%;
    margin-top: 0;
    animation: slideInFromLeft 1s ease-out;
}

.contact-details {
    font-size: 1.2em;
    color: #555;
}

.contact-details p {
    margin: 10px 0;
}

.contact-details a {
    color: #007bff;
    text-decoration: none;
}

.contact-details a:hover {
    text-decoration: underline;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
